<script>
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {        
    },
    methods: {
        
    },
};
</script>

<template>
    <div>
        <hr class="my-2 mt-3" />
        <h4 class="card-title">Margenes</h4>
        <div class="row">
            <div class="col-6">
                <div class="form-group" title="Superior(mm)" v-b-tooltip.hover>
                    <input id="template-setting-margin-top" v-model="value.margin.top" type="text"
                        class="form-control" placeholder="Superior(mm)"
                        :class="{ 'is-invalid': v.template.settings.margin.top.$error && submitted }" />
                    <div v-if="v.template.settings.margin.top.$error && submitted" class="invalid-feedback">Valor inválido</div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group" title="Inferior(mm)" v-b-tooltip.hover>
                    <input id="template-setting-margin-bottom" v-model="value.margin.bottom" type="text"
                        class="form-control" placeholder="Inferior(mm)"
                        :class="{ 'is-invalid': v.template.settings.margin.bottom.$error && submitted }" />
                    <div v-if="v.template.settings.margin.bottom.$error && submitted" class="invalid-feedback">Valor inválido</div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group" title="Izquierdo(mm)" v-b-tooltip.hover>
                    <input id="template-setting-margin-left" v-model="value.margin.left" type="text"
                        class="form-control" placeholder="Izquierdo(mm)"
                        :class="{ 'is-invalid': v.template.settings.margin.left.$error && submitted }" />
                    <div v-if="v.template.settings.margin.left.$error && submitted" class="invalid-feedback">Valor inválido</div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group" title="Derecho(mm)" v-b-tooltip.hover>
                    <input id="template-setting-margin-right" v-model="value.margin.right" type="text"
                        class="form-control" placeholder="Derecho(mm)"
                        :class="{ 'is-invalid': v.template.settings.margin.right.$error && submitted }" />
                    <div v-if="v.template.settings.margin.right.$error && submitted" class="invalid-feedback">Valor inválido</div>
                </div>
            </div>
        </div>                        
        <hr class="my-2 mt-3" />
        <h4 class="card-title">Tamaño de papel</h4>
        <div class="row">
            <div class="col-6">
                <div class="form-group" title="Ancho(mm)" v-b-tooltip.hover>
                    <input id="template-setting-width" v-model="value.width" type="text"
                        class="form-control" placeholder="Ancho(mm)"
                        :class="{ 'is-invalid': v.template.settings.width.$error && submitted }" />
                    <div v-if="v.template.settings.width.$error && submitted" class="invalid-feedback">Valor inválido</div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group" title="Alto(mm)" v-b-tooltip.hover>
                    <input id="template-setting-height" v-model="value.height" type="text"
                        class="form-control" placeholder="Alto(mm)"
                        :class="{ 'is-invalid': v.template.settings.height.$error && submitted }" />
                    <div v-if="v.template.settings.height.$error && submitted" class="invalid-feedback">Valor inválido</div>
                </div>
            </div>                            
        </div>                               
    </div>
</template>