<script>
import Editor from '@tinymce/tinymce-vue'
import QlkMedia from "@/components/upload/qlk-media.vue";

// const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
// const isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;

export default {
    components: {
        Editor,
        QlkMedia
    },
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        },
        tagToInsert: String,
        stylesToApply: String,
    },
    data() {
        return {
            apiKey: process.env.VUE_APP_TINYMCE_API_KEY,
            uploadModalId: 'add-individual-media-modal',
            config: {
                toolbar_mode: 'sliding',
                plugins: [
                    'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                    'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'mentions', 'tinycomments',
                ],
                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                tinycomments_mode: 'embedded',
                tinycomments_author: 'Author name',
                height: 500,
            },
            config2: {
                selector: 'textarea#open-source-plugins',
                plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
                editimage_cors_hosts: ['picsum.photos'],
                menubar: 'file edit view insert format tools table help',
                toolbar: "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                link_list: [
                    { title: 'My page 1', value: 'https://www.tiny.cloud' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                ],
                image_list: [
                    { title: 'My page 1', value: 'https://www.tiny.cloud' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                ],
                image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' }
                ],
                importcss_append: true,
                file_picker_types: 'image',
                images_file_types: 'jpg',
                file_picker_callback: (callback/*, value, meta*/) => {

                    this.showUploadModal();


                    // Escuchamos el evento `onSuccess` del modal para insertar la imagen
                    this.$on('image-selected', (img) => {
                        callback(img?.url); // Insertamos la imagen en TinyMCE
                    });

                    // console.log(999)
                    // /* Provide file and text for the link dialog */
                    // if (meta.filetype === 'file') {
                    // callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                    // }

                    // /* Provide image and alt text for the image dialog */
                    // if (meta.filetype === 'image') {
                    // callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                    // }

                    // /* Provide alternative source and posted for the media dialog */
                    // if (meta.filetype === 'media') {
                    // callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                    // }
                },
                height: 500,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image table',
                // skin: useDarkMode ? 'oxide-dark' : 'oxide',
                // content_css: useDarkMode ? 'dark' : 'default',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                // setup: function(editor) {
                //     editor.on('BeforeSetContent', function(e) {
                //         // Reemplazar el contenido del pagebreak
                //         e.content = e.content.replace(/<!-- pagebreak -->/g, '<div class="page-break"></div>');
                //     });
                // }
            }
        };
    },
    methods: {
        showUploadModal() {
            this.$bvModal.show(this.uploadModalId);            
        },
        insertContentToEditor(content) {
            const editor = this.$refs.tinymceEditor.editor;
            if (editor) {
                editor.insertContent(content);
            }
        },
        insertTag(tag) {
            this.insertContentToEditor(`{{${tag}}}`);
        },
        insertImg(img) {
            this.$emit('image-selected', img);
            // this.insertContentToEditor(`<img src="${img?.url}">`);
        },
        applyCustomStyles(styles) {
            // Crear un URL Blob con los estilos CSS personalizados
            const blob = new Blob([styles], { type: 'text/css' });
            const styleURL = URL.createObjectURL(blob);

            const editor = this.$refs.tinymceEditor.editor;
            if (editor) {
                editor.dom.loadCSS(styleURL);
            }
        },
    },
    watch: {
        tagToInsert(newTag) {
            if (newTag) {
                this.insertTag(newTag);
            }
        },
        stylesToApply(styles) {
            if (styles) {
                this.applyCustomStyles(styles);
            }
        }
    },
};
</script>

<template>
    <div>
        <QlkMedia :modal-id="uploadModalId" :single="true" @onSuccess="insertImg"/>
        <Editor ref="tinymceEditor" v-model="value.content" :api-key="apiKey" :init="config2" />
        <div v-if="!v.template.content.required && submitted" class="invalid-feedback d-block">Este campo es requerido
        </div>
    </div>
</template>