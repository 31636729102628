<script>
import Vue from "vue";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import TemplateForm from "./forms/template-form.vue";
import AddButton from "@/components/buttons/add-button.vue";
import api from "@/helpers/apirest/api";

/**
 * Stores Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    AddButton,
    TemplateForm,
  },
  data() {
    return {
      modalId:'template-form-modal',      
      autorefreshInterval: 5,
      intervalId: null,
      pageHeader:{
        title: "Plantillas",
        breadcrumbs: [
          {
            text: "Plantillas",
            href: "/templates",
            active: true
          }
        ],
      },
      templates: [],
      selectedItemId:null, 
      showSelectedItemId:null,
    };
  },
  methods: {
    showEditModal(item) {
      this.selectedItemId = item?.id || null;
      this.$bvModal.show(this.modalId);
    },
    onSuccess(){
      this.loadData();
    },
    async loadData() {
        try {            
            const response = await api.get({
                url:'templates',
                params:{
                  with_trashed: 1
                }
            });

            const templates = response?.data?.data;

            templates.map(item => {
                item.soft_delete_status = (item?.deleted_at) ? 0 : 1;
            });

            this.templates = templates;
        } catch (error) {
          this.templates = [];
        }
    },
    async deleteTemplate(template){
        const result = await Vue.swal({
          title: "Eliminar plantilla",
          icon: "info",
          html:  `Está seguro que desea eliminar la plantilla: ${template?.name}?<br>La accion no se puede deshacer!`,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
        });

        if (result.value) {
          await api.delete({
              url: 'templates',
              id: template?.id,
              force: true
          });

          this.loadData();
        }        
    },
    async softDeleteRow(item){
        if(!item?.deleted_at){
            await api.delete({
                url: 'templates',
                id: item?.id
            });
        }else{
            await api.post({
                url:`templates/${item?.id}/restore`
            });
        }

        this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <TemplateForm :modal-id="modalId" :id="selectedItemId" @onSuccess="onSuccess"></TemplateForm>    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body d-flex justify-content-between">
            <p class="text-muted font-13">Administra tus plantillas para gestionar los diferentes procesos.</p>
            <div class="text-right">
              <AddButton @click="showEditModal"></AddButton>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6" v-for="template in templates" :key="template.id">
        <div class="card h-100" :class="{'opacity-5': template?.deleted_at}">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-checkbox
                  v-model="template.soft_delete_status"
                  switch class="mb-1"
                  value="1"
                  @change="softDeleteRow(template)"
              ></b-form-checkbox>
              <b-dropdown right class="qlk-dropdown-sm" variant="white" size="sm">
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                </template>
                <b-dropdown-item @click="showEditModal(template)">Editar</b-dropdown-item>
                <b-dropdown-item @click="deleteTemplate(template)">Eliminar</b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="text-center">
              <img :src="template?.media?.url" alt class="avatar-sm mt-2 mb-4" />
              <div class="media-body">
                <h5 class="text-truncate">
                  <a href="#" class="text-dark">{{template?.name}}</a>
                </h5>
                <p class="text-muted" v-if="!template?.current_template_product_import">
                  <!-- <i class="mdi mdi-calendar-clock mr-1" v-b-tooltip.hover title="Última sincronización"></i> {{template?.last_sync_at || 'No sincronizado'}} -->
                </p>            
              </div>
            </div>
            <!-- <hr class="my-4" />
            <div class="row text-center">
              <div class="col-6">
                <p class="text-muted mb-2">Productos</p>
                <h5>{{ template?.products_count }}</h5>
              </div>
              <div class="col-6">
                <p class="text-muted mb-2">Pedidos</p>
                <h5>{{ template?.active_orders_count }}</h5>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>